.mobile-menu {
  position: fixed;
  width: 100vw;
  /* height: 100vh; */
  background-color: white;
  z-index: 20;
  overflow-y: scroll;
}

.mobile-header {
  width: 100%;
  text-align: center;
  background-color: #31325e;
  height: 50px;
  color: white;
}

.menu-close-icon {
  position: absolute;
  right: 50px;
  cursor: pointer;
}
.menu-close-icon:hover {
  color: darkorange;
}

.mobile-menu-list {
  padding: 50px 0 0 50px;
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  flex-direction: column;
  height: 100%;
}

.mobile-menu-link {
  border-bottom: 0.5px solid rgba(137, 137, 202, 0.363);
  padding-bottom: 15px;
  position: relative;
}
.menu-text:hover {
  padding-left: 0px;
  transition: 0.5s;
}

.menu-text {
  text-decoration: none;
  padding-left: 20px;
  color: #000;
  transition: 0.5s;
  cursor: pointer;
}

.mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 20;
  /* animation: swipin 1s; */
  transform: translate(0%);
  transition: 1s;
}
.mobile-menu-out {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 20;
  /* animation: swipout 1s; */
  transform: translate(-100%);
  transition: 1s;
}

@keyframes swipin {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(0%);
  }
}
@keyframes swipout {
  0% {
    transform: translate(0%);
  }
  100% {
    transform: translate(-100%);
  }
}

.mobile-sub-menu {
  /* transform: scaleY(1); */
  height: 330px;
  /* margin-left: 30px; */
  /* margin-top: 15px; */
  /* margin-bottom: 3px; */
  transition: .5s;
  overflow: hidden;
  /* border: 1px solid red; */
}
.closed {
  height: 0px !important;
  /* transform: scaleY(0); */
  /* margin-left: 30px; */
  transition: .5s;
  overflow: hidden;
}
.mobile-sub-menu ul {

  height: 300px;
  overflow: hidden;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-left: 30px;
  background-color: #f7e8da;
  color: #000 !important;
}

.mobile-sub-menu ul .hr{
  width: 100%;
}
.mobile-sub-menu ul li {
  color: #000 !important;
}
.mobile-sub-menu ul {
  /* margin-top: 20px; */
}

.triangle-mobile {
  width: 30px;
  height: 20px;
  top: 0;
  /* margin-top: -13px; */
  padding-left: 13px;
  /* overflow: hidden; */
}

.triangle-point-mobile {
  background-color: #f7e8da;
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  width: 100%;
  height: 100%;
  top: 0;
  /* margin-top: -13px; */
  margin-left: 5px;
  border: 1px solid #f7e8da;
}

.add-icon-mobile-menu {
  position: absolute;
  right: 5vw;
  top: 0px;
}
.add-icon-mobile-menu:hover {
  color: darkorange;
  cursor: pointer;
}
