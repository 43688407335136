.header-full{
    width: 100%;
    height: 600px;
}
.header-small{
    width: 100%;
    height: 300px;
}

.header{
    color: white;
    background-size: cover;
    background-position:center;

    background-color: black;
    justify-content: center;
    position: relative;
    gap: 30px;
}

.header-img{
    background-size: cover;
    background-position:top;

    position: absolute;
    height: 100%;
    width: 100%;
    opacity: .5;

}

.header-text h1, .header-text p{

    max-width: 600px;
    font-style: italic;
    padding: 0;
    margin: 0;
    text-align: center;
    
}

.header-text{
    z-index: 2;
    padding-top: 10%;

}


@media(max-width:700px){
    .header-text p{
        padding: 0 20px;
    }

    .header-text h1, .header-text p{
        font-size: 2rem !important;
        
    }
}
 