.nav-bar {
  padding: 0 10%;
  height: 100px;
  background-color: #ffffffe0;
  filter: blur(30);
  z-index: 10;

  position: fixed;
  top: 0;
  width: calc(100vw - 20%);
  animation: name .7s  ease-in-out forwards;
  filter: drop-shadow(1px 1px 6px #00000033);

}
li {
    color: black;
  }

.nav-links {
  height: 100%;
}
.logo {
  /* background-image: url("../../Assets/black_moplaco.png"); */
  background-size: cover;
}

.nav-links {
  gap: 20px;
  font-size: 1vw;
  position: relative;
  max-width: 60vw;

  transition: .7s;
}

.nav-links p {
  cursor: pointer;
}

.sub-menu {
  position: absolute;
  height: 400px;
}
.sub-menu ul {
  height: 400px;
}

.sub-links {
  height: 400px;
  flex-direction: column;
  justify-content: space-evenly;

  overflow: hidden;
  width: 15vw;
  border-radius: -10px;
  padding: 20px 0%;
  background-color: #f7e8da;
}
.sub-links li{
    padding-left: 100px;
    padding-right: 100px;
}
.triangle {
  width: 8%;
  height: 20px;
  top: 0;
  margin-top: -13px;
  padding-left: 13px;
  overflow: hidden;
}

.triangle-point {
  background-color: #f7e8da;
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  width: 100%;
  height: 100%;
  top: 0;
  /* margin-top: -13px; */
  margin-left: 5px;
  border: 1px solid #f7e8da;
}
.sub-links .hr {
  width: 90%;
  height: 1px;
  filter: none;
  box-shadow: none;
  background-color: darkgray;
}
.sub-links li {
  cursor: pointer;
}

.sub-menu ul {
  animation: shrinkLeft 400ms ease-in-out forwards;
  transform-origin: left center;
  padding: 0 40px;
}
.triangle {
  animation: shrinkLeft1 400ms ease-in-out forwards;
  transform-origin: left center;
}
.nav-links li:hover > .sub-menu ul {
  height: 400px;
  transition-delay: 2s;
  animation: growRight 400ms ease-in-out forwards;
}
.nav-links li:hover > .sub-menu .triangle {
  transition-delay: 2s;
  animation: growRight1 400ms ease-in-out forwards;
}
.nav-links li ul li a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes growRight {
  0% {
    transform: scaleX(0);
    display: none;
  }
  100% {
    transform: scaleX(1);
    display: flex;
  }
}
@keyframes shrinkLeft {
  0% {
    transform: scaleX(1);
    display: flex;
  }
  100% {
    transform: scaleX(0);
    display: none;
  }
}

@keyframes growRight1 {
  0% {
    width: 0;
    display: none;
  }
  100% {
    width: 8%;
    display: flex;
  }
}
@keyframes shrinkLeft1 {
  0% {
    width: 8%;
    display: flex;
  }
  100% {
    width: 0;
    display: none;
  }
}

.sub-links li:hover {
  color: darkgoldenrod;
}

.logo-container p {
    color: white;
    z-index: 3;
    text-align: center;
    height: 10%;
    font-weight: 700;
    font-size: 1.2vw !important;
    text-wrap: nowrap;
    display: none;
  }
  .logo-container     {
    flex-direction: column;
    justify-content: center;
    width: 10vw;
  }
  .logo {
    height:70px !important;
    width: 70px;
    /* background-image: url("../../Assets/black_moplaco.png"); */
    background-size: cover;
  animation: spin 3s infinite ease-out alternate;

  }

  .btn p{
    color: white;
  }
  .btn:hover p{
    color: black;
  }
  
/********************************* FOR HERO ****************************/
.logo-container.hero-part    {
  flex-direction: column;
  justify-content: center;
  width: 10vw;
  padding-top: 5%;
}
.hero-part.logo {
  height: 9vw !important;
  width: 9vw;
  /* background-image: url("../../Assets/white_moplaco.png"); */
  background-size: cover;
}

.hero-part.logo-container p {
  color: white;
  z-index: 3;
  text-align: center;
  height: 10%;
  font-weight: 700;
  font-size: 1vw;
  text-wrap: nowrap;
  display: inherit;
}

.hero-part li{
  color: white;
}
.hero-part li p{
  color: white;
}
.nav-bar.hero-part{
  background-color: transparent;
  height: 250px;
  position: absolute !important;
  padding: 0 10% ;
  width: calc(100vw - 20%);
  /* animation: slideIn 400ms; */
}

.hero-part .nav-links{
gap: 40px;

}
.sub-links li{
    color: black;
    font-size: 1vw;


}
.slid-in{
    /* gap: 0vw; */
    transition: .7s;
    animation: name .7s  ease-in-out forwards;
  }
@keyframes name {
    0%{
        transform:scaleX(30) !important

    }
    100%{
        transform:scaleX(1) !important

    }
}
/* ******************************* MOBILE ****************************************************** */
 
@media(max-width:1200px){
  .nav-links{
    display: none;
   
  }

  .nav-bar{
    justify-content: center;
    background-color: rgb(245, 242, 242);
    height: 90px !important;
  }

  .logo {
    height:70px !important;
    width: 70px !important;
    background-size: cover;
  animation: spin 3s infinite ease-out alternate;

  }

  .logo.hero-part{
    height:100px !important;
    width: 100px !important;
    margin-top: 50px;
  }

  .logo-container p{
    display: none !important
  };
 


}



/* .mobile-menu-togler{
  transition: 1s;
  display: none;
} */
