.footer-section .main{
    /* height: 650px; */
    width: 100vw;
    align-items: flex-start;
    padding-top: 50px;
}
.footer-section .copyright{
    width: 100vw;
    height: 80px;
    background-color: lightgray;
    justify-content: center;
    font-size: 1.2rem;
    text-align: center;
    text-wrap:stable
}
span{
    padding: 0 10px;
}
.footer-child ul li{
    color: black;
    padding-left: 40px;
    font-size: 1.3rem;
    padding-bottom: 30px ;
}
.footer-section .main  .footer-child{
    flex: 1;
    /* border: 1px solid red; */
    height: 100%;
    
}
.footer-title{
    font-size: 1.9rem;
}
.moplaco-text{
    font-size: 1.9rem !important;
}
.description, .contact, .hours{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    padding: 0 5%;
}
.description p, .description a{
    font-size: 1.3rem;
}
.description a{
    color: black;
}

.footer-sub-title{
    font-size: 1.2rem;
}
.contact  li, .hours li{
    font-size: 1.3rem;

}
.hr {
    width: 80%;
    height: 1px;
    filter: none;
    box-shadow: none;
    background-color: darkgray;
    /* margin-top: 30px; */
  }

  .--bold{
    font-weight: 600;
  }


  @media(max-width:1000px){
    .main{
        flex-direction: column !important;
        align-items: center !important;
    }
    .description, .contact, .hours{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; 
        padding: 5% 5%;
        text-align: center;
    }
    /* .footer-section .copyright{
      
        font-size: 2.3vw;
    } */
    .footer-child ul li{ 
        padding-left: 0px; 
    }
    .footer-section .copyright{
      padding: 20px;
      font-size: 1rem;
      height: 50px;
      width: calc(100vw - 20px);
    }
    .footer-section .copyright span{
      display: block;
    }
  }
 