body,
html{
width: 100%;
/* height: 100%; */
margin: 0;
overflow-x: hidden;
/* background-color: black; */
scroll-behavior: smooth;
}
*{
    font-family: "Cormorant Garamond", serif;

}
.--flex-center{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

.--black{
    color: black;
}
.--white{
    color: white;
}


.--flex-center-center{
    display: flex;
    justify-content: center;
    align-items: center;

}

.--column{
    flex-direction: column;
}



/* BUTTON */

.btn {
    height: 60px;
    padding: 0 1.3rem;
    background-color: #31325e;
    border-radius: 30px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
  }
.btn-small {
    height: 60px;
    padding: 0 1.3rem;
    background-color: #31325e;
    border-radius: 30px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn:hover {
    height: 58px;
    padding: 0 calc(1.3rem - 1px);
    background-color: white;
    border-radius: 30px;
    color: #31325e;
    border: 1px solid #31325e;
  }
  

  .transform{
    transform: translateY(100%) !important;
    transition: 1s; 
}
 .detransform{
    transform: translateY(0%) !important;
    transition: 1s; 
}


section{
    overflow: hidden;
}

.--semi-bold{
    font-weight: 400;
}
.--semi-semi-bold{
    font-weight: 500;
}

.--width-100vw{
width: 100vw;
}

.--display-none{
    display: none;
}


@keyframes spin {
    from {
      /* transform: rotate(0deg); */
      transform: rotateY(180deg);

    }
    to {
      /* transform: rotate(360deg); */
      transform: rotateY( 0deg);
    }
  }

@keyframes spin-round {
    from {
      transform: rotate(0deg);

    }
    to {
      transform: rotate(360deg);
    }
  }



  .logo-preloader {
    height:6vw !important;
    width: 6vw;
    /* background-image: url("../../Assets/black_moplaco.png"); */
    background-size: cover;
  animation: spin-round 3s infinite ease-out alternate;

  }
@media(max-width:1000px){
  
  .logo-preloader {
    height:100px !important;
    width: 100px;
    /* background-image: url("../../Assets/black_moplaco.png"); */
    background-size: cover;
  animation: spin-round 3s infinite ease-out alternate;

  }
}
  .rotate-box {
    width: 200px; height: 50px;
    border: 2px solid #aaa;
    background: #feffd6;
    transform: rotate(-10deg);
    /* transform: perspective(500px); */
    transform-style: preserve-3d;
  }

  .--drop-shadow{
    filter: drop-shadow(5px 5px 7px #000000);
  }
  

  .--no-underline{
    text-decoration: none;
    /* border: 1px solid red; */
    height: fit-content;
    width: fit-content;
  }



  .--borderline{
    border: 1px solid red;
  }

  .--borderline-blue{
    border: 1px solid blue;
  }

  
 .--width300{
  width: 300px;
}
 .--width200{
  width: 200px;
}


.--fs-1{
  font-size: 1rem;
}

.--fs-1-5{
  font-size: 1.5rem;
}
.--fs-2{
  font-size: 2rem;
}
.--fs-3{
  font-size: 3rem;
}
.--fs-3-5{
  font-size: 3.5rem;
}

 
.--clean-btn{
  text-decoration: none;
  color: #000000;
}