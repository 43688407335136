.partners-image,
.partners-text {
  /* width: 100vw;
    height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.pp1{ width: 50vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;

    border: 1px solid red;
    background-color: red;}


.pp2{ width: 50vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
    background-color: blue;
    color: black;
} */

.fit-width {
  width: 100% !important;
  overflow: hidden;
  /* border: 2px solid green; */
  padding: 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.slick-slider-destroy-gap {
  height: 50vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 50vw !important;
}

.pp1, .pp2{
    height: 100% !important;
    width: 100% !important;
    border: 1px solid red !important;
    background-color: red;
}