.hero-section{
    width: 100%;
    height: 100vh;
    justify-content: center;
    background-color: black; 
    position: relative;
}
.hero{
    /* background-image: url("../../Assets/homepagebanner.jpg"); */
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
    opacity: .5;
    background-color: black;
    position: absolute;
}

.hero-section  h1 {
    padding-top: 10%;
    color: #ffffff;
    font-size: 50px;
    font-weight: 600;
    text-align: center;
    z-index: 2;
    font-style: italic;
}





.about-section{
    justify-content: center;
    background: #EBE4D4;
    overflow: hidden;
}
.about{
    flex-direction: column;
    width: 80%;
    transform: translateY(50%);
    transition: 1s; 

}

.about  h1 {
    padding-top: 5%;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    z-index: 2;
    font-style: italic;
    color: #000;

}

.about p{
    font-size:  1.5rem;
    text-align: justify;
    line-height: 230%!important;
    padding-bottom: 5%;

}








/* ******************************************* QUOTE ********************************************** */
.farm{
    padding: 85px 10%; 
    transform: translateY(50%);

}

.quote-container{
    background-image: url("../../Assets/quoteBanner.jpg");
    background-size: cover;
    background-position: center;
    padding: 1.7rem;
    width: 70%;

}
.quote-text-container{
    
    background-color: white;
    padding: 1% 5% 0% 5%;
    font-size: 1.5rem;
    text-align: center;
    height: 300px;
    justify-content: space-evenly;
}

.quoted{
   font-size: 1rem;
   width: 100%;
   text-align: end;
}


.steps{
    width: 100vw;
    justify-content: space-evenly ;
    padding: 5% 0 3% 0;

}

.steps-image{
    height: 327px;
    width: 300px;
    background-size: cover;

}
.exporters .steps-image{
    background-image: url("../../Assets/exporter.png");
    
}
.processors .steps-image{
    background-image: url("../../Assets/processor.png");
}
.farmers .steps-image{
    background-image: url("../../Assets/farmer.png");
}

.steps p{
    text-align: center;
    font-size: 1.8rem;
}





 /* *********************************OUR FARM***************************************************************** */

 .our-farms{
    height: 100vh;
    width: 100vw;
    background-image: url("../../Assets/ourFarm.jpg");
    background-attachment: fixed;

 }


 .our-farms .card{
    /* width: 50%; */
    /* height: auto; */
    /* padding: 10%; */
    max-width: 50%;
    background-color: whitesmoke;
    border-radius: 40px;
    font-size: 1.8rem;
    font-style: italic;
 }

 .our-farms .card p{
    padding: 70px;
    text-align: justify;
    line-height: 160%;

}


.our-farms .title{
    font-size: 3rem;
    color: whitesmoke;
    font-style: italic;
}

.partnership{
    height: 100vh;
    background-color: #EBE4D4;
}


@media(max-width:1000px){

    .steps{
        flex-direction: column;
    }

    .our-farms .card{
        max-width: 90%;
    font-size: 1.5rem;

    }

    .our-farms .card p{
        padding: 0 30px;
        text-align: justify;
    }
    
}

.btn-small {
    font-size: 1.5rem;
  }





  /* ********************** CONTACT SECTION **************************************** */

  .contact-section{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #DEDEDE!important;
  }


  .fit-container{
    width: 100% !important;
    height: 100vh;
    
  }
form {
    height: 100vh;
    /* border: 1px solid red; */
    display: flex;
    /* justify-content: center; */
    align-items: stretch;
    flex-direction: column;
    justify-content: space-evenly;


}

.form-group{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

form p{
    text-align: center;

}
input{
    width: 40%;
    display: inline;
    border: none;
    border-bottom: 1px solid #2B1A12;
    outline: none;
    background: #DEDEDE!important;  
}
 
.form-group-last{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}
.form-group-last textarea{
    width:85%;
    outline: none;
    text-transform: uppercase;

    border: none;
    border-bottom: 1px solid #2B1A12;
    padding: 0;
    margin: 0;
    min-height: 180px;
    background: #f0f0f0;
    resize: none;
}

.--gap-30px{

    gap: 30px;
}

.form-controller{
    justify-content: space-evenly;
    height: 300px;
}

input::placeholder, textarea::placeholder {
    font-size: 1rem;
    text-transform: uppercase;

}


@media(max-width:1000px){
    .contact-section{
        flex-direction: column !important;
    }
}